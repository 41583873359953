import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addComment } from '../../firebase.js';
import './style.css';

const CommentForm = ({ parentId, slug, submitCallback }) => {
  const [name, setName] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [content, setContent] = useState('');
  const [validVal, setValidVal] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  let timerId;

  useEffect(() => {
    if (error) {
      timerId = setTimeout(() => {
        setError('');
      }, 2500);
    }
  }, [error]);

  useEffect(() => {
    return clearTimeout(timerId);
  }, []);

  const handleCommentSubmission = async (e) => {
    e.preventDefault();
    let comment = {
      user_name: name,
      user_site: siteUrl,
      content: content,
      valid: validVal,
      email: email,
      post_slug: slug,
      parent_id: parentId || null,
      time: new Date().getTime(),
    };

    addComment(comment)
      .then((ret) => {
        setName('');
        setContent('');
        setSiteUrl('');
        setEmail('');
        submitCallback && submitCallback(comment);
      })
      .catch((e) => {
        setError('Comment sending failed, refresh please...');
      });
  };

  return (
    <div className="fallback-fadein">
      {error && <div className="fallback-fadein comment-error">{error}</div>}
      <form
        className="comment-form"
        onSubmit={(e) => handleCommentSubmission(e)}
      >
        <label htmlFor="name">
          Name
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label htmlFor="email">
          Email
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label htmlFor="siteUrl">
          Site
          <input
            type="text"
            id="siteUrl"
            value={siteUrl}
            onChange={(e) => setSiteUrl(e.target.value)}
          />
        </label>
        <label htmlFor="comment">
          Comment
          <textarea
            id="comment"
            name="comment"
            onChange={(e) => setValidVal(e.target.value)}
            value={validVal}
            style={{
              width: 0,
              height: 0,
              padding: 0,
              margin: 0,
              border: 'none',
            }}
          ></textarea>
          <textarea
            id="commentx"
            onChange={(e) => setContent(e.target.value)}
            value={content}
            name="commentx"
            required="required"
            cols="45"
            rows="8"
          ></textarea>
        </label>
        <button type="submit" className="btn">
          提交
        </button>
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  parentId: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

export default CommentForm;
