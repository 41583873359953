import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_APIKEY,
  appId: process.env.GATSBY_FIREBASE_APPID,
  authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASEURL,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGINGSENDERID,
  projectId: process.env.GATSBY_FIREBASE_PROJECTID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();

// 检测连接状态
export const connectedRef = db.ref('.info/connected');

const firebaseRef = db.ref(`/comments`);

function _convertDicToSortedArray(comments) {
  var commentsArray = [];
  for (const commentId in comments) {
    var comment = comments[commentId];
    comment['id'] = commentId;
    commentsArray.push(comment);
  }

  commentsArray.sort(function (comment1, comment2) {
    return comment1.timestamp > comment2.timestamp;
  });
  return commentsArray;
}

export const getCommentsByPostSlug = (slug) => {
  return new Promise((resolve, reject) => {
    firebaseRef
      .orderByChild('post_slug')
      .equalTo(slug)
      .once('value', (snapshot) => {
        const posts = _convertDicToSortedArray(snapshot.val());
        posts &&
          posts
            .filter((doc) => doc.post_slug === slug)
            .map((doc) => {
              return { id: doc.id, ...doc };
            });
        resolve(posts || []);
      });
  });
};

export const addCommentChangeListener = (slug, callback) => {
  firebaseRef.on('value', (snapshot) => {
    const posts = _convertDicToSortedArray(snapshot.val());
    posts &&
      posts
        .filter((doc) => doc.post_slug === slug)
        .map((doc) => {
          return { id: doc.id, ...doc };
        });
    callback(posts);
  });
};

export const addComment = (comment, callback) => {
  if (comment.valid !== '') {
    return new Promise((resolve, reject) => {
      reject('error');
    });
  }
  const id = firebaseRef.push().key;
  return db.ref(`/comments/${id}`).set({ ...comment });
};

export default firebase;
