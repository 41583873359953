import React, { useEffect, useState } from 'react';
import { connectedRef } from '../firebase';

const useDBConnection = () => {
  const [connectState, setConnectState] = useState(false);
  useEffect(() => {
    connectedRef.on('value', function (snap) {
      setConnectState(snap.val());
    });
  }, []);
  return connectState;
};

export default useDBConnection;
