const _ = require('lodash');
import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import useDBConnection from '../hooks/useDBConnection';
import Comments from '../components/comments/CommentList';
import {
  getCommentsByPostSlug,
  addCommentChangeListener,
} from '../firebase.js';

// import { Disqus, CommentCount } from 'gatsby-plugin-disqus';

import '../fonts/fonts-post.css';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import Panel from '../components/Panel';
import { formatPostDate, formatReadingTime } from '../utils/helpers';
import { rhythm, scale } from '../utils/typography';
import {
  codeToLanguage,
  createLanguageLink,
  loadFontsForCode,
  replaceAnchorLinksByLanguage,
} from '../utils/i18n';
import ShapeHex from '../components/shapeHex';

const GITHUB_USERNAME = 'venmax';
const GITHUB_REPO_NAME = 'blog';
const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

class Translations extends React.Component {
  render() {
    let { translations, lang, languageLink, editUrl } = props;

    let readerTranslations = translations.filter((lang) => lang !== 'ru');
    let hasRussianTranslation = translations.indexOf('ru') !== -1;

    return (
      <div className="translations">
        <Panel style={{ fontFamily: systemFont }}>
          {translations.length > 0 && (
            <span>
              {hasRussianTranslation && (
                <span>
                  Originally written in:{' '}
                  {'zh-hans' === lang ? (
                    <b>{codeToLanguage('zh-hans')}</b>
                  ) : (
                    <Link to={languageLink('zh-hans')}>中文</Link>
                  )}
                  <br />
                  <br />
                </span>
              )}
              <span>Translated by readers into: </span>
              {readerTranslations.map((l, i) => (
                <React.Fragment key={l}>
                  {l === lang ? (
                    <b>{codeToLanguage(l)}</b>
                  ) : (
                    <Link to={languageLink(l)}>{codeToLanguage(l)}</Link>
                  )}
                  {i === readerTranslations.length - 1 ? '' : ' • '}
                </React.Fragment>
              ))}
            </span>
          )}
          {lang !== 'zh-hans' && (
            <>
              <br />
              <br />
              {
                <>
                  <Link to={languageLink('zh-hans')}>Read the original</Link>
                  {' • '}
                  <a href={editUrl} target="_blank" rel="noopener noreferrer">
                    Improve this translation
                  </a>
                  {' • '}
                </>
              }
              <Link to={`/${lang}`}>View all translated posts</Link>{' '}
            </>
          )}
        </Panel>
      </div>
    );
  }
}

const BlogPostTemplate = (props) => {
  const dbState = useDBConnection();
  const [comments, setComments] = useState(null);
  const post = props.data.markdownRemark;
  const siteTitle = get(props, 'data.site.siteMetadata.title');
  let {
    previous,
    next,
    slug,
    translations,
    translatedLinks,
  } = props.pageContext;
  const cmtSlug = slug.replace(/\//g, '');
  const lang = post.fields.langKey;

  // Replace original links with translated when available.
  let html = post.html;

  useEffect(() => {
    // const cleanUp = firestore
    //   .collection(`comments`)
    //   .onSnapshot(snapshot => {
    //     const posts = snapshot.docs
    //     .filter(doc => doc.data().post_slug === cmtSlug)
    //     .map(doc => {
    //       return { id: doc.id, ...doc.data() }
    //     });
    //     setComments(posts)
    //   })
    if (dbState) {
      getCommentsByPostSlug(cmtSlug).then((posts) => {
        setComments(posts);
      });
      addCommentChangeListener(cmtSlug, (items) => {
        setComments(items);
      });
    }
  }, [slug, dbState]);

  const render = () => {
    // Replace original anchor links by lang when available in whitelist
    // see utils/whitelist.js
    html = replaceAnchorLinksByLanguage(html, lang);

    translatedLinks.forEach((link) => {
      // jeez
      function escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      let translatedLink = '/' + lang + link;
      html = html.replace(
        new RegExp('"' + escapeRegExp(link) + '"', 'g'),
        '"' + translatedLink + '"'
      );
    });

    translations = translations.slice();
    translations.sort((a, b) => {
      return codeToLanguage(a) < codeToLanguage(b) ? -1 : 1;
    });

    loadFontsForCode(lang);
    // TODO: this curried function is annoying
    const languageLink = createLanguageLink(slug, lang);
    const enSlug = languageLink('zh-hans');
    const editUrl = `https://github.com/${GITHUB_USERNAME}/${GITHUB_REPO_NAME}/tree/master/src/pages/${enSlug.slice(
      1,
      enSlug.length - 1
    )}/index${lang === 'zh-hans' ? '' : '.' + lang}.md`;
    const discussUrl = `https://mobile.twitter.com/search?q=${encodeURIComponent(
      `https://blog.axiu.me${enSlug}`
    )}`;
    const postDay = new Date(post.frontmatter.date).getDate();

    // let disqusConfig = {
    //   url: `https://blog.axiu.me${enSlug}`,
    //   identifier: post.id,
    //   title: post.frontmatter.title,
    // };
    return (
      <Layout location={props.location} title={siteTitle}>
        <SEO
          lang={lang}
          title={post.frontmatter.title}
          description={post.frontmatter.spoiler}
          slug={post.fields.slug}
        />
        <ShapeHex
          style={{
            background: 'var(--borderColor)',
            height: 608,
            width: 350,
            right: 20,
            top: 10,
            zIndex: 0,
            transform: `rotate(${postDay}deg)`,
          }}
        ></ShapeHex>
        <main
          style={{
            flex: 1,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(28),
            marginTop: '6rem',
            zIndex: 2,
            padding: '0 24px',
            width: '100%',
            background: 'var(--bg)',
          }}
        >
          <article>
            <header>
              <h1 style={{ color: 'var(--textTitle)', lineHeight: '1.2' }}>
                {post.frontmatter.title}
              </h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: 'block',
                  marginBottom: rhythm(0.8),
                  marginTop: rhythm(-4 / 5),
                  color: '#666',
                }}
              >
                {formatPostDate(post.frontmatter.date, lang)}
                {` • `}{' '}
                {comments ? <span>{comments.length} comments</span> : '...'}
                {` • `}{' '}
                <span title="以每分钟265计算">
                  {formatReadingTime(post.timeToRead)}
                </span>
              </p>
              {translations.length > 0 && (
                <Translations
                  translations={translations}
                  editUrl={editUrl}
                  languageLink={languageLink}
                  lang={lang}
                />
              )}
            </header>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </article>
          <p>
            {post.frontmatter.tags.map((item) => {
              return (
                <a
                  key={`link-${item}`}
                  style={{
                    marginRight: 6,
                    fontSize: '0.9rem',
                    color: 'var(--textTitle)',
                    border: '1px solid var(--textTitle)',
                    padding: '2px 5px',
                  }}
                  href={`/tag/${_.kebabCase(item)}`}
                >
                  #{item}
                </a>
              );
            })}
          </p>
        </main>
        <aside
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            maxWidth: rhythm(28),
          }}
        >
          <div
            style={{
              margin: '40px 0 20px 0',
              fontFamily: systemFont,
            }}
          ></div>
          <h3
            style={{
              marginTop: rhythm(0.25),
            }}
          ></h3>
          <nav>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link
                    to={previous.fields.slug}
                    rel="prev"
                    style={{ marginRight: 20 }}
                  >
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </aside>

        {dbState ? (
          post.frontmatter.comments == false ? (
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                maxWidth: rhythm(28),
              }}
              className="loading block"
            >
              Comments Off
            </div>
          ) : (
            <Comments comments={comments} slug={cmtSlug} />
          )
        ) : null}

        {/* <Disqus config={disqusConfig} /> */}
        {/* <p> */}
        {/* <a href={discussUrl} target="_blank" rel="noopener noreferrer">
                  Discuss on Twitter
                </a>
                {` • `} */}
        {/* <a
            href={editUrl}
            target="_blank"
            style={{ color: 'var(--textTitle)', fontSize: rhythm(0.5) }}
            rel="noopener noreferrer"
          >
            <svg
              fill="currentColor"
              preserveAspectRatio="xMidYMid meet"
              height="1em"
              width="1em"
              viewBox="0 0 40 40"
              className="css-9u48bm"
              style={{
                color: 'var(--textTitle)',
                marginBottom: '3px',
                marginRight: '3px',
                verticalAlign: 'middle',
              }}
            >
              <g>
                <path d="m34.5 11.7l-3 3.1-6.3-6.3 3.1-3q0.5-0.5 1.2-0.5t1.1 0.5l3.9 3.9q0.5 0.4 0.5 1.1t-0.5 1.2z m-29.5 17.1l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z"></path>
              </g>
            </svg>
            Edit this page on GitHub
          </a> */}
        {/* </p> */}
        <Footer />
      </Layout>
    );
  };
  return render();
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        comments
        date(formatString: "MMMM DD, YYYY")
        spoiler
        tags
      }
      fields {
        slug
        langKey
      }
    }
  }
`;
