import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Comment from './Comment';
import CommentForm from './CommentForm';
import './style.css';

const CommentList = (props) => {
  const slug = props.slug;
  const [showForm, setShowForm] = useState(true);
  const comments = props.comments;
  const replyCallback = (state) => {
    setShowForm(!!state);
  };

  const render = () => {
    if (comments === null) {
      return (
        <div className="comment-list">
          <div className="loading block">loading...</div>
        </div>
      );
    }
    return (
      <div className="comment-list">
        <h2>Join the discussion</h2>
        {comments.length > 0 &&
          comments
            // .filter((comment) => !comment.parent_id)
            .sort((a, b) => a.time - b.time)
            .map((comment) => {
              let children;
              if (!comment.parent_id) {
                children = comments
                  .filter((c) => comment.id === c.parent_id)
                  .sort((a, b) => b.time - a.time);
                return (
                  <Comment
                    key={comment.id}
                    children={children}
                    comment={comment}
                    slug={slug}
                    replyCallback={replyCallback}
                  />
                );
              }
            })}
        {showForm && <CommentForm slug={slug} />}
      </div>
    );
  };
  return render();
};

CommentList.propTypes = {
  slug: PropTypes.string.isRequired,
  comments: PropTypes.array,
};

export default CommentList;
