import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CommentForm from './CommentForm';
import { getGravatar } from '../../utils/helpers';
import './style.css';

const SingleComment = memo(({ comment, replyCallback, slug }) => {
  const [showReplyBox, setShowReplyBox] = useState(false);

  useEffect(() => {
    if (typeof showReplyBox === 'boolean') {
      replyCallback(!showReplyBox);
    }
  }, [showReplyBox]);

  const getUserInfo = () => {
    if (comment.user_site) {
      return (
        <a href={comment.user_site} target="_blank">
          {comment.user_name}
        </a>
      );
    }
    return comment.user_name;
  };

  const getReply = () => {
    if (!comment.parent_id) {
      if (showReplyBox) {
        return (
          <div className="reply-box">
            <div>
              <span className="btn bare" onClick={() => setShowReplyBox(false)}>
                Cancel Reply
              </span>
              <CommentForm
                parentId={comment.id}
                slug={slug}
                submitCallback={(comment) => {
                  setShowReplyBox(false);
                }}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className="reply-box">
            <span className="btn bare" onClick={() => setShowReplyBox(true)}>
              Reply
            </span>
          </div>
        );
      }
    }
  };
  return (
    <div className="comment-block">
      <div className="user-info">
        <div className="flex avatar">
          <img src={getGravatar(comment.email)} alt="Avatar" />
        </div>
        <div className="flex user">
          <span className="comment-author">{getUserInfo()}</span>
          <span className="comment-time">
            {comment.time && (
              <time>{new Date(comment.time).toLocaleString()}</time>
            )}
          </span>
        </div>
      </div>
      <div className="comment-content">{comment.content}</div>
      {getReply()}
    </div>
  );
});

const Comment = memo(({ comment, children, slug, replyCallback }) => {
  return (
    <div className="fallback-fadein">
      <SingleComment
        comment={comment}
        slug={slug}
        replyCallback={replyCallback}
      />
      {children &&
        children.map((item) => (
          <div
            className="fallback-fadein comment-block comment-reply"
            key={item.id}
          >
            <SingleComment
              comment={item}
              slug={slug}
              replyCallback={replyCallback}
            />
          </div>
        ))}
    </div>
  );
});

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  slug: PropTypes.string,
  child: PropTypes.object,
};

export default Comment;
